import Router from '@/router';
import { withRouter } from 'react-router-dom';
import "./index.scss";
import { observer, inject } from 'mobx-react';
import Sider from './sider';
function DocumentCon({ history, location }) {
  return (
    <div className="document">
      <div className="document-view">
        <div className="document-layout">
          <Sider />
          <div className="document-content" >
            <Router />
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(inject('store')(observer(DocumentCon)))