import { Switch, Route, Redirect } from 'react-router-dom';
import MD from '@/components/md';

// 文档页路由配置
export const menu = [
    {
        title: "项目说明",
        cons: [
            {
                tit: "需求背景",
                route: "/docs/项目说明/需求背景",
                md: '/docs/README.md',
                position: '0'
            },
            {
                tit: '项目目标',
                route: "/docs/项目说明/项目目标",
                md: '/docs/README.md',
                position: '300'
            },
            {
                tit: '竞品分析',
                route: "/docs/项目说明/竞品分析",
                md: '/docs/README.md',
                position: '500'
            },
            {
                tit: '购买流程',
                route: "/docs/项目说明/购买流程",
                md: '/docs/README.md',
                position: '1500'
            }
        ]
    },
    {
        title: '接入流程',
        cons: [
            {
                tit: '接入开通',
                route: '/docs/接入流程/接入开通',
                md: '/docs/GUILD.md',
                position: '0'
            },
            {
                tit: '后端用户查询及发货接口',
                route: '/docs/接入流程/后端用户查询及发货接口',
                md: '/docs/GUILD.md',
                position: '1150'
            },
            {
                tit: '前端用户购买页',
                route: '/docs/接入流程/前端用户购买页',
                md: '/docs/GUILD.md',
                position: '1420'
            }
        ]
    },
    {
        title: '接口详述',
        cons: [
            {
                tit: '后端接口',
                route: '/docs/后端接口',
                md: '/docs/API-Serv.md'
            },
            {
                tit: '前端接口',
                route: '/docs/前端接口',
                md: '/docs/API-Front.md'
            },
            {
                tit: '币商转账接口',
                route: '/docs/币商转账接口',
                md: '/docs/CoinDealer-Trans.md'
            },
            {
                tit: '币商代充接口',
                route: '/docs/币商代充接口',
                md: '/docs/CoinDealer-Pay.md'
            }
        ]
    },
    {
        title: 'Q&A',
        cons: [
            {
                tit: '',
                route: '/docs/qa',
                md: '/docs/QA.md'
            }
        ]
    }
];
let routes = [];
menu.forEach(({ cons }) => {
    cons.forEach((r) => routes.push(r))
})

const Router = () => {
    return (
        <Switch>
            {
                routes.map(el => {
                    return (
                        <Route path={el.route} key={el.route} component={() => <MD path={el.md} />} exact />
                    )
                })
            }
            <Redirect from='/docs' to='/docs/项目说明/需求背景' exact />
        </Switch>
    )
}

export default Router;