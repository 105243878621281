import React from 'react';
import styles from './shop.module.scss';
import { Carousel } from 'antd';
import nimo from '@/assets/topic/nimo.png';
import zhunxin from '@/assets/topic/zhunxin.png';
import hy from '@/assets/topic/huya.png';
import Fade from 'react-reveal/Fade';
import Btns from '../btns';
const Shop = () => {
    return (
        <Fade Top>
            <div className={styles.shop} id='payment_shop'>
                <div className={styles.shop_desc}>
                    <p className={styles.title1}>一站式交付</p>
                    <p className={styles.title2}>灵活定制的支付商城</p>
                    <p className={styles.title3}>
                        高达99.99%的系统可用性、99.95%优质发货率，拥有一套完善丰富的交易管理后台服务
                    </p>
                    <p className={styles.modules}>
                        可定制模块包括
                    </p>
                    <ul className={styles.moduleName}>
                        <li>商城样式</li>
                        <li>域名</li>
                        <li>Google/IOS/Xiaomi/Huawei</li>
                    </ul>
                    <Btns />
                </div>
                <div className={styles.topics}>
                    <Carousel
                        autoplay
                        dots={{ className: 'dots' }}
                        effect="fade"
                    >
                        <div>
                            <img src={nimo} alt='nimo' />
                        </div>
                        <div>
                            <img src={hy} alt='huya' />
                        </div>
                        <div>
                            <img src={zhunxin} alt='zhunxin' />
                        </div>
                    </Carousel>
                </div>
            </div>
        </Fade>

    )
}
export default Shop;