import { useState, useEffect } from "react";
import MarkDown from '@uiw/react-markdown-preview';
import { observer, inject } from 'mobx-react';
const MD = ({ path, store }) => {
    const [md, setMd] = useState('# Loading...');
    useEffect(() => {
        fetch('.' + path)
            .then(res => res.text())
            .then(res => {
                setMd(res);
                store.setMd(res);
            });

    }, [path, store])
    return (
        <MarkDown source={md} />
    )
}
export default inject('store')(observer(MD))