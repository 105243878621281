import React from 'react';
import styles from './terminals.module.scss';
import phone from '@/assets/image/phone.png';
import watch from '@/assets/image/watch.png';
import order1 from '@/assets/image/order1.png';
import Fade from 'react-reveal/Fade'
const Terminals = () => {
    return (
        <Fade Top>
            <div className={styles.terminals} id='terminals'>
                <div className={styles.desc}>
                    <p className={styles.title1}>
                        一键部署
                    </p>
                    <p className={styles.title2}>
                        多终端的支付能力
                    </p>
                    <p className={styles.title3}>
                        基于PaySuite SDK为App/小程序快速接入支付能力
                    </p>
                </div>
                <div className={styles.terminal}>
                    <div className={styles.phone} >
                        <img src={phone} alt='phone' />
                    </div>

                    <div className={styles.watch} >
                        <img src={watch} alt='watch' />
                    </div>
                    <div className={styles.order}>
                        <img src={order1} alt='order1' />
                    </div>
                </div>
            </div>
        </Fade>
    )
}
export default Terminals;
