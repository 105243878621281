import React, { useState, useCallback } from 'react';
import { inject, observer } from 'mobx-react';
import styles from './header.module.scss';
import logo from '@/assets/image/paysuite-logo.png';
import menu from '@/assets/image/menu.png';
import paysuite from '@/assets/icon/paysuite.png';
import { Button } from 'antd';
import { withRouter } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import '@/assets/css/h5reset.scss';
const Header = ({ store, history }) => {
    const [visible, setVisible] = useState(false);
    const go2console = useCallback(() => {
        window.open('https://console.paysuite.cn');
    }, []);
    const submenu = [
        {
            icon: require('@/assets/icon/payment.png').default,
            name: '支付网关',
            id: 'channel'
        },
        {
            icon: require('@/assets/icon/shop.png').default,
            name: '充值商城',
            id: 'shop'
        },
        {
            icon: require('@/assets/icon/package.png').default,
            name: '快捷收款',
            id: 'terminals'
        },
        {
            icon: require('@/assets/icon/trans.png').default,
            name: '转账收款',
            id: 'transfer'
        }
    ];
    const handleClick = useCallback((id) => {
        const el = document.getElementById(id);
        el.scrollIntoView();
        return setVisible(false);
    }, [setVisible]);
    const go2docs = useCallback(() => {
        history.push('/docs')
    }, [history]);
    return (
        <Fade Top>
            <div className={styles.header}>
                <div className={styles.navbar}>
                    <a href="/"><img src={logo} alt='logo' /></a>
                    <img src={menu} alt='menu' className={styles.menuBtn}
                        onClick={() => setVisible(true)}
                    />
                </div>
                <div className={styles.desc}>
                    <img src={paysuite} alt='paysuite' />
                    <h2 className={styles.subtitle}>一站式支付云平台</h2>
                    <p>
                        通过 <i>PaySuite</i> 强大的 API 和专门设计用来帮您获取更多收入的软件解決方案进行全球收款和资金转移。<i>PaySuite</i> , 致力于让每一个 App 都能轻松连接上支付。
                    </p>
                    <div className={styles.btn_group}>
                        <Button className={styles.trial}
                            onClick={go2console}
                        >
                            免费试用
                        </Button>
                        <Button type='link'
                            className={styles.contack}
                        // onClick={() => store?.setShow(true)}
                        >
                            联系销售
                        </Button>
                    </div>
                </div>
                {
                    visible ?
                        (
                            <div className={styles['popover']}>
                                <p className={styles['close-btn']}
                                    onClick={() => setVisible(false)}
                                >
                                    ×
                                </p>
                                <h3>产品</h3>
                                <div className={styles['submenu']}>
                                    {
                                        submenu.map(({ name, id, icon }) => {
                                            return (
                                                <div
                                                    className={styles['submenu-item']}
                                                    key={name}
                                                    onClick={() => handleClick(id)}
                                                >
                                                    <div
                                                        className={styles['submenu-imgw']}
                                                    >
                                                        <img src={icon} alt={icon} />
                                                    </div>
                                                    <div>
                                                        {name}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className={styles['docs']}

                                >
                                    <button onClick={go2docs}>开发者中心</button>
                                </div>
                            </div>
                        )
                        :
                        null
                }
            </div>
        </Fade>
    )
}

export default inject('store')(observer(withRouter(Header)))