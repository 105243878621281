import React, { useCallback } from 'react';
import styles from './footer.module.scss';
import logo from '@/assets/image/paysuite-logo.png';
import hy from '@/assets/icon/hy.png';
import Fade from 'react-reveal/Fade';
import { withRouter } from 'react-router-dom';
const Footer = ({ history }) => {
    const list = [
        {
            name: '支付网关',
            id: 'payment_gateway'
        },
        {
            name: '充值商城',
            id: 'payment_shop'
        },
        {
            name: '快捷收款',
            id: 'payment_terminals'
        },
        {
            name: '转账收款',
            id: 'payment_transfer'
        }
    ];
    const handleClick = useCallback((id) => {
        const el = document.getElementById(id);
        if (el) {
            el.scrollIntoView()
        } else {
            history.push('/');
            setTimeout(() => {
                document.getElementById(id)?.scrollIntoView();
            }, 100)
        }
    }, [history]);
    const go2docs = useCallback(() => {
        window.scrollTo(0, 0)
        return history.push('/docs/项目说明/需求背景');
    }, [history])
    return (
        <Fade Top>
            <div className={styles.footer_wrap}>
                <div className={styles.linear_line}>

                </div>
                <div className={styles.footer}>
                    <div className={styles.item}>
                        <img src={logo} alt='logo' />
                    </div>
                    <div className={styles.item}>
                        <ul>
                            <li className={styles.title}>
                                产品
                            </li>
                            {
                                list.map(({ id, name }) => {
                                    return (
                                        <li key={id}>
                                            <span
                                                onClick={() => handleClick(id)}
                                            >
                                                {name}
                                            </span>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    <div className={styles.item}>
                        <ul>
                            <li className={styles.title}>
                                帮助中心
                            </li>
                            <li>
                                <span
                                    onClick={go2docs}
                                >
                                    开发者中心
                                </span>
                            </li>

                        </ul>
                    </div>
                    <div className={styles.item}>
                        <p className={styles.title}>
                            联系我们
                        </p>
                        <p className={styles.desc}>
                            获取定制化的支付方案，请联系我们，专业的咨询顾问将为您提供更多的业务可能性。
                        </p>
                    </div>
                </div>
                <div className={styles.footer_bot}>
                    <p>佛山虎牙虎信科技有限公司  © 2021 ｜ <a href='https://beian.miit.gov.cn'>粤ICP备2021090130号</a></p>
                    <p >
                        <img src={hy} alt='hy' />
                    </p>
                </div>
            </div>
        </Fade>

    )
}

export default withRouter(Footer);