import React from 'react';
import Header from '@/components/h5_header';
import Channel from '@/components/h5_channel';
import Shop from '@/components/h5_shop';
import Terminals from '@/components/h5_terminals';
import Transfer from '@/components/h5_transfer';
import Choice from '@/components/h5_choice';
import Footer from '@/components/h5_footer';
const H5Index = () => {
    return (
        <div>
            < Header />
            <Channel />
            <Shop />
            <Terminals />
            <Transfer />
            <Choice />
            <Footer />
        </div>
    )
}

export default H5Index