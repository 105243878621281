import React from 'react';
import styles from './header.module.scss';
import Navbar from '../navbar';
import Banner from '../banner';
import Fade from 'react-reveal/Fade';
const Header = () => {
    return (
        <Fade Top>
            <header className={styles.header_wrap}>
                <div className={styles.header}>
                    <Navbar />
                    <Banner />
                </div>
            </header>
        </Fade>
    )
}
export default Header