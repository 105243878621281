import React, { useCallback } from 'react';
import { Button } from 'antd';
import styles from './index.module.scss';
import { observer, inject } from 'mobx-react';
const Btns = ({ store }) => {
    const openConsole = useCallback(() => {
        window.open('https://console.paysuite.cn');
    }, []);
    return (
        <p className={styles.btn_group}>
            <Button className={styles.toBegin}
                onClick={openConsole}
            >
                开始试用 &gt;
            </Button>
            <Button type='link' className={styles.contact}
                onClick={() => store.setShow(true)}
            >
                联系销售
            </Button>
        </p>
    )
}
export default inject('store')(observer(Btns));