import Header from '@/components/header';
import Channels from '@/components/channels';
import Shop from '@/components/shop';
import Terminals from '@/components/terminals';
import Transfer from '@/components/transfer';
import Choice from '@/components/choice';
import Footer from '@/components/footer';
import Contact from '@/components/contact';
import '@/assets/css/reset.css';

function Index() {
    return (
        <div className='pc'>
            <Header />
            <Channels />
            <Shop />
            <Terminals />
            <Transfer />
            <Choice />
            <Footer />
            <Contact />
        </div>
    );
}

export default Index