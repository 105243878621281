import { observable, action, makeAutoObservable } from 'mobx';

class Store {
    constructor() {
        makeAutoObservable(this);
    }
    @observable md = '';
    @action.bound setMd(md) {
        this.md = md
    }
    // 弹框状态
    @observable show = false;
    @action.bound setShow(val) {
        this.show = val;
    }
}
export default new Store();