import React, { useCallback, useEffect, useState } from 'react';
import { menu } from '@/router';
import { withRouter } from 'react-router';

// 标题位置
const titles = {
    '/项目说明': ['/需求背景', '/项目目标', '/竞品分析', '/购买流程'],
    '/接入流程': ['/接入开通', '/后端用户查询及发货接口', '/前端用户购买页'],
}
const Sider = ({ location, history }) => {
    const [current, setCurrent] = useState(
        location.pathname === '/' ? '/docs/项目说明/需求背景' : location.pathname
    );
    const goto = useCallback((route, position) => {
        if (route.split('/')[2] === location.pathname.split('/')[2]) {
            setCurrent(route);
            if (position) return window.scrollTo(0, position);
        }
        window.onscroll = null;
        // window.scrollTo(0, 0);
        if (position) {
            window.scrollTo(0, position)
        } else {
            window.scrollTo(0, 0);
        }
        setCurrent(route);
        history.push(route);
    }, [history, location.pathname]);
    const titleLink = useCallback((route) => {
        history.push(route);
        setCurrent(route);
        window.onscroll = null;
        window.scrollTo(0, 0);
    }, [history])
    useEffect(() => {
        window.onscroll = null;
        window.onscroll = () => {
            let pathname = '/' + location.pathname.split('/')[2];
            let { scrollY } = window;

            if (!titles[pathname]) {
                return
            }

            if (pathname === '/项目说明') {
                if (scrollY > 0 && scrollY < 300) {
                    setCurrent('/docs' + pathname + titles[pathname][0]);
                } else if (scrollY >= 300 && scrollY < 500) {
                    setCurrent('/docs' + pathname + titles[pathname][1]);
                } else if (scrollY >= 500 && scrollY < 1500) {
                    setCurrent('/docs' + pathname + titles[pathname][2]);
                } else if (scrollY >= 1500) {
                    setCurrent('/docs' + pathname + titles[pathname][3]);
                }
            }
            if (pathname === '/接入流程') {
                if (scrollY > 0 && scrollY < 1150) {
                    setCurrent('/docs' + pathname + titles[pathname][0]);
                } else if (scrollY >= 1150 && scrollY < 1420) {
                    setCurrent('/docs' + pathname + titles[pathname][1]);
                } else if (scrollY >= 1420) {
                    setCurrent('/docs' + pathname + titles[pathname][2]);
                }
            }
        }
        // 销毁时解绑事件监听
        return () => {
            window.onscroll = null;
        }
    }, [location.pathname])
    return (
        <div className="document-sider" >
            {
                menu.map((menu) => {
                    const { title, cons } = menu
                    return (
                        <div key={title}

                        >
                            <div className="route-title"
                                onClick={() => { titleLink(cons[0]['route']) }}
                            >
                                {title}
                            </div>
                            {
                                cons.map((con) => {
                                    const { tit, route, position } = con;
                                    return (
                                        <div
                                            className={current === route ? 'route-con selected' : 'route-con'}
                                            key={tit}
                                            onClick={() => goto(route, position)}
                                        >
                                            {tit}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}

export default withRouter(Sider);