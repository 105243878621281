import React from 'react';
import classes from './index.module.scss';
import { observer, inject } from 'mobx-react';
const Contact = ({ store }) => {
    return (
        <div className={classes['contact-w']}
            style={{
                display: store.show === true ?
                    'flex'
                    :
                    'none'
            }}
        >
            <div className={classes['contact-con']}>
                <span className={classes['close-btn']}
                    onClick={() => store.setShow(false)}
                >
                    ×
                </span>
                <h2 className={classes['title']}>联系我们的销售团队</h2>
                <div className={classes['form-w']}>
                    <div className={classes['label']}>
                        您的姓名
                    </div>
                    <div className={classes['input-w']}>
                        <input
                            placeholder='请输入您的姓名'
                        />
                    </div>
                    <div className={classes['label']}>
                        联系方式
                    </div>
                    <div className={classes['input-w']}>
                        <input
                            placeholder='请输入手机、邮箱、QQ或微信号'
                        />
                    </div>
                    <div className={classes['label']}>
                        您的需求
                    </div>
                    <div className={classes['input-w']}>
                        <textarea
                            placeholder='请输入你的需求（例如：......）'
                        />
                    </div>
                    <div className={classes['submit-btn']}>
                        <button >
                            提交
                        </button>
                    </div>
                </div>
            </div>
        </div >
    )
}
export default inject('store')(observer(Contact))