import React, { useCallback } from 'react';
import styles from './navbar.module.scss';
import logo from '@/assets/image/paysuite-logo.png';
import { withRouter } from 'react-router';
import { Button } from 'antd';
const submenu = [
    {
        icon: require('@/assets/icon/payment.png').default,
        name: '支付网关',
        id: 'payment_gateway'
    },
    {
        icon: require('@/assets/icon/shop.png').default,
        name: '充值商城',
        id: 'payment_shop'
    },
    {
        icon: require('@/assets/icon/package.png').default,
        name: '快捷收款',
        id: 'payment_terminals'
    },
    {
        icon: require('@/assets/icon/trans.png').default,
        name: '转账收款',
        id: 'payment_transfer'
    }
]
const Navbar = ({ history }) => {
    const openConsole = useCallback(() => {
        window.open('https://console.paysuite.cn');
    }, []);
    return (
        <div className={styles.navbar}>
            <a href='/'>
                <img src={logo} alt='logo' />
            </a>
            <ul className={styles.link}>
                <li className={styles['prods']}>
                    <span onClick={() => history.push('/')}>产品</span>
                    <div className={styles.sublink}>
                        {
                            submenu.map(({ icon, name, id }) => {
                                return (
                                    <div className={styles['link-item']}
                                        key={id}
                                        onClick={() => {
                                            const el = document.getElementById(id);
                                            if (el) {
                                                el.scrollIntoView();
                                            } else {
                                                history.push('/');
                                                setTimeout(() => {
                                                    document.getElementById(id)?.scrollIntoView();
                                                }, 100)
                                            }
                                        }}
                                    >
                                        <div className={styles['img-w']}>
                                            <img src={icon} alt={icon} />
                                        </div>
                                        {name}
                                    </div>
                                )
                            })
                        }
                    </div>
                </li>
                <li onClick={() => history.push('/docs')}>开发者中心</li>
                <li>
                    <Button onClick={openConsole}>
                        控制台 &gt;
                    </Button>
                </li>
            </ul>
        </div>
    )
}

export default withRouter(Navbar);