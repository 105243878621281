import React from 'react';
import styles from './terminal.module.scss';
import phone from '@/assets/image/phone.png';
import order from '@/assets/image/order1.png';
import watch from '@/assets/image/watch.png';
import bg from '@/assets/image/order_bg.png';
import nimo from '@/assets/image/nimo.png';
import member from '@/assets/image/member.png';
import price from '@/assets/image/price.png';
import card from '@/assets/image/card.png';
import info from '@/assets/image/info.png';
import button from '@/assets/image/Button.png';
import bottom from '@/assets/image/paysuite_bottom.png';
import Fade from 'react-reveal/Fade';
const Terminals = () => {
    return (
        <Fade Top>
            <div className={styles.terminals_wrap} id='payment_terminals'>
                <div className={styles.terminals_con}>
                    <div className={styles.desc}>
                        <p className={styles.title1}>一键部署</p>
                        <p className={styles.title2}>多终端的支付能力</p>
                        <p className={styles.title3}>基于PaySuite SDK为App/小程序快速接入支付能力</p>
                    </div>
                    <img src={phone} alt='phone' className={styles.phone} />
                    <img src={watch} alt='watch' className={styles.watch} />
                    <img src={order} alt='order' className={styles.order} />
                    <div className={styles.payment}>
                        <div className={styles.con}>
                            <img src={bg} alt='bg' className={styles.bg} />
                            <img src={nimo} alt='nimo' className={styles.logo} />
                            <img src={member} alt='member' className={styles.member} />
                            <img src={price} alt='price' className={styles.price} />
                            <img src={card} alt='card' className={styles.card} />
                            <img src={info} alt='info' className={styles.info} />
                            <img src={button} alt='button' className={styles.button} />
                            <img src={bottom} alt='bottom' className={styles.bottom} />
                            <ul className={styles.pay_methods}>
                                <li className={styles.first}>银行卡支付</li>
                                <li>支付宝支付</li>
                                <li>微信支付</li>
                                <li>Paypal</li>
                                <li > ...</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Fade>

    )
}

export default Terminals;