import DocumentHeader from '@/components/DocumentHeader';
import DocumentCon from '@/components/DocumentCon';
import DocumentFooter from '@/components/footer';
import { useEffect } from 'react';
const Doc = () => {
    useEffect(() => {
        document.title = 'PaySuite文档'
    }, [])

    return (
        <div>
            <DocumentHeader />
            <DocumentCon />
            <DocumentFooter />
        </div>
    )
}
export default Doc