import React, { useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import styles from './footer.module.scss';
import logo from '@/assets/image/paysuite-logo.png';
import hy from '@/assets/icon/hy.png';
import Fade from 'react-reveal/Fade';
const Footer = ({ history }) => {
    const list = [
        {
            name: '支付网关',
            id: 'channel'
        },
        {
            name: '充值商城',
            id: 'shop'
        },
        {
            name: '快捷收款',
            id: 'terminals'
        },
        {
            name: '转账收款',
            id: 'transfer'
        }
    ];
    const handleClick = useCallback((id) => {
        const el = document.getElementById(id);
        return el.scrollIntoView()
    }, []);
    const go2docs = useCallback(() => {
        return history.push('/docs/项目说明/需求背景');
    }, [history])
    return (
        <Fade Top>
            <div className={styles.footer}>
                <div className={styles.linearbar}>
                </div>
                <img src={logo} alt='logo' className={styles.logo} />
                <div className={styles.link}>
                    <ul>
                        <li>
                            产品
                        </li>
                        {
                            list.map(({ name, id }) => {
                                return (
                                    <li key={id}>
                                        <a onClick={() => handleClick(id)}>{name}</a>
                                    </li>
                                )
                            })
                        }
                    </ul>
                    <ul>
                        <li>
                            帮助中心
                        </li>
                        <li>
                            <a onClick={go2docs}>开发者中心</a>
                        </li>
                    </ul>
                </div>
                <div className={styles.contact}>
                    <p className={styles.title}>
                        联系我们
                    </p>
                    <p className={styles.subtitle}>
                        获取定制化的支付方案，请联系我们，专业的咨询顾问将为您提供更多的业务可能性。
                    </p>
                </div>

                <p className={styles.copyright}>
                    佛山虎牙虎信科技有限公司  © 2021 ｜ <a href='https://beian.miit.gov.cn'> 粤ICP备2021090130号</a>
                </p>
                <p className={styles.hy}>
                    <img src={hy} alt='hy' />
                </p>
            </div>
        </Fade>

    )
}
export default withRouter(Footer)