import React from 'react';
import styles from './channel.module.scss';
import channelPic from '@/assets/image/h5_channel.png';
import Fade from 'react-reveal/Fade'
const Channel = () => {
    return (
        <Fade Top>
            <div className={styles.channel} id='channel'>
                <div className={styles.desc}>
                    <p className={styles.title1}>统一的支付网关</p>
                    <p className={styles.title2}>一套 API 对接国内海外主流支付渠道</p>
                    <p className={styles.title3}>高达99.99%的系统可用性、99.95%优质发货率，拥有一套完善丰富的交易管理后台服务</p>
                </div>
                <img src={channelPic} alt='channel' className={styles.channelPic} />
            </div>
        </Fade>

    )
}

export default Channel;