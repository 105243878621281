import React from 'react';
import styles from './banner.module.scss';
import phone from '@/assets/img/phone.png';
import Podcast from '@/assets/img/Podcast.png';
import Btns from '../btns';
const Banner = () => {
    return (
        <div className={styles.banner}>
            <div className={styles.banner_text}>
                <p className={styles.paysuite}></p>
                <p className={styles.subtitle}>一站式支付云平台</p>
                <p className={styles.describe}>
                    通过 <i>PaySuite</i> 强大的 API 和专门设计用来帮您获取更多收入的软件解決方案进行全球收款和资金转移。<i>PaySuite</i>  , 致力于让每一个 App 都能轻松连接上支付。
                </p>
                <Btns />
            </div>
            <img src={phone}
                className={styles.phone}
                alt='phone'
            />
            <div className={styles.chart}>
                <img src={Podcast}
                    className={styles.Podcast}
                    alt='Podcast'
                />
            </div>
        </div>
    )
}
export default Banner;