import React from 'react';
import styles from './choice.module.scss';
import map from '@/assets/image/map.png';
import indian from '@/assets/image/indian.png';
import Fade from 'react-reveal/Fade';
const Choice = () => {
    return (
        <Fade Top>
            <div className={styles.choice}>
                <div className={styles.desc}>
                    <p className={styles.title1}>
                        一站式支付
                    </p>
                    <p className={styles.title2}>
                        转账支付
                    </p>
                    <p className={styles.title3}>
                        国内支持微信、支付宝、银行卡等账户账户类型 海外支持Payonner、Airwallex、Paypal多种账户类型，支持流程配置，灵活满足你的付款需求。
                    </p>
                </div>
                <div className={styles.map_wrap}>
                    <img src={map} alt='map' className={styles.map} />
                    <img src={indian} alt='indian' className={styles.indian} />
                </div>
            </div>
        </Fade>

    )
}

export default Choice