import React from 'react';
import styles from './transfer.module.scss';
import channel1 from '@/assets/image/channel1.png';
import channel2 from '@/assets/image/channel2.png';
import channel3 from '@/assets/image/channel3.png';
import Fade from 'react-reveal/Fade';
const Transfer = () => {
    return (
        <Fade Top>
            <div className={styles.transfer} id='payment_transfer'>
                <div className={styles.desc}>
                    <p className={styles.title1}>一站式支付</p>
                    <p className={styles.title2}>转账支付</p>
                    <p className={styles.title3}>
                        国内支持微信、支付宝、银行卡等账户账户类型 海外支持Payonner、Airwallex、Paypal多种账户类型， 支持流程配置，灵活满足你的付款需求。
                    </p>
                </div>
                <div className={styles.channels}>
                    <img src={channel1} alt='channel1' />
                    <img src={channel2} alt='channel2' className={styles.channel2} />
                    <img src={channel3} alt='channel3' className={styles.channel3} />
                </div>
            </div>
        </Fade>

    )
}
export default Transfer;