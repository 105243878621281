import React from 'react';
import styles from './channels.module.scss';
import group from '@/assets/image/Group.png';
import frame from '@/assets/image/frame.png';
import Fade from 'react-reveal/Fade';
const Channels = () => {
    return (
        <Fade Top>
            <div className={styles.channels_wrap} id='payment_gateway'>
                <div className={styles.channels}>
                    <p className={styles.payment}>统一的支付网关</p>
                    <h2 className={styles.title}>一套 API 对接国内海外主流支付渠道</h2>
                    <p className={styles.describe}>高达99.99%的系统可用性、99.95%优质发货率，拥有一套完善丰富的交易管理后台服务</p>
                    <div className={styles.channels_con}>
                        <div className={styles.left}>
                            <p className={styles.channels_payment}>更多的支付渠道</p>
                            <p className={styles.channels_desc}>接受全球客户所有的主流借记卡和信用卡。银行卡付款占全球线上付款的比率超过了 40%。</p>
                            <ul className={styles.payments}>
                                <li>微信支付</li>
                                <li>支付宝支付</li>
                                <li>银行卡支付</li>
                                <li>Mastercard</li>
                                <li>VISA</li>
                                <li>Paypal</li>
                                <li>...</li>
                            </ul>
                        </div>
                        <div className={styles.right}>
                            <img src={group} alt='group' className={styles.earth} />
                            <img src={frame} alt='frame' className={styles.frame} />
                        </div>
                    </div>
                </div>
            </div>
        </Fade>
    )
}

export default Channels;