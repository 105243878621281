import React from 'react';
import './global.scss';
import PC from '@/views/pc';
import H5Index from './views/mobile';
import Docs from './views/doc';
import { Switch, Route } from 'react-router-dom';

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  window.navigator.userAgent
);


function App() {
  return (
    <Switch>
      <Route path='/' render={() => {
        document.title = 'PaySuite'
        return isMobile ?
          <H5Index />
          :
          <PC />
      }} exact />
      <Route path='/docs' component={Docs} />
    </Switch>
  );
}

export default App;
