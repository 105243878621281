import React from 'react';
import styles from './choice.module.scss';
import { Button } from 'antd';
import map from '@/assets/image/map.png';
import indian from '@/assets/image/indian.png';
import Fade from 'react-reveal/Fade';
import Btns from '../btns';
const Choice = () => {
    return (
        <Fade Top>
            <div className={styles.choice_wrap}>
                <div className={styles.choice}>
                    <div className={styles.desc}>
                        <p className={styles.title1}>
                            为什么选择 PaySuite?
                        </p>
                        <p className={styles.title2}>
                            我们的优势
                        </p>
                        <p className={styles.title3}>
                            支付安全：恶退率&lt; 1% <br />渠道全球覆盖率：110+国
                        </p>
                        <Btns />
                    </div>
                    <div className={styles.right}>
                        <img src={map} alt='map' className={styles.map} />
                        <img src={indian} alt='indian' className={styles.indian} />
                    </div>
                </div>
            </div>
        </Fade>
    )
}

export default Choice